.topSpace {
    margin: 20px;
    margin-top: 100px;
}

.tableSection {
    margin-left: 20px;


}

.loginMain {
    height: 80vh;
    margin: auto;
    width: 50%;
    margin-top: 70px;
}