.topForm {
  margin: 20px;
  margin-top: 60px;
}
.mainClassInvoice {
  padding-bottom: 100px;
}
.genderDrop {
}
.searchTop {
  float: right;
}
.sartUserForm {
  clear: both;
}
.addTest {
  background-color: #67c2d0;
}
.white {
  color: white;
}
.cirlceDiv {
  height: 2.5rem;
  width: 2.5rem;
  background-color: #0e9f6e;
  border-radius: 50px;
  color: white;
  font-weight: bold;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
}
.center {
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
}

.redAccent {
  background-color: #31c48d;
}
#bottomAddTest {
  height: 80px;
  padding: 30px;

  text-align: center;
}
#bottomAddTest button{
    margin: 10px;
}
#bottomAddTest i {
  padding-left: 10px;
  padding-right: 10px;
}
.bottomSubmet{
    margin: 30px;
   
}
.bottomSubmet i{
    padding-left: 10px;
    padding-right: 10px;
}
.bagTest span {
  margin-left: 2px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
