

.page-header-space {
    width: 29.7cm;
    height:10.2cm;
}
.page-header {
    position: fixed;
    top: 0mm;
    left: 2px;
    margin-bottom: 0px;
    width: 29.7cm;
    height: 10.4cm;

}


#headerImg {
    max-width: 100%;
    max-height: 100%;
    /* padding-right: -119mm !important; */
    /* object-fit: fill; */
    width:  29.7cm;
    display: block;
}
.page-footer-space {

    width: 29.7cm;
    height: 270px;
}

.page-footer {
    position: fixed;
    bottom: 0;
    width: 29.7cm;
    height: 150px;
}

#footerImg {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.personInfo {
    position: absolute;
    width: 29.7cm;
    /* padding-left: 25px;
    padding-right: 25px; */
    bottom: 0;
    right: 0;
    left: 0px;

}
*{
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    -webkit-print-color-adjust: exact !important;


}


.page {
    page-break-after: always;
}

@page {
    margin: 0mm

}

@media print {
    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
    }


    table tr.highlighted > td {
        background-color: rgba(247, 202, 24, 0.3) !important;
    }


    .page-divide {
        page-break-after: always;
    }
}
