.topSpace{
    margin: 20px;
    margin-top: 80px;
}
.tableSection{
    margin-left: 20px;
    margin-top: 80px;

}

.topForm {
    margin: 20px;
    margin-top: 80px;
}
.mainClassInvoice {
    padding-bottom: 100px;
}


.btn-circle.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    font-size: 8px;
    text-align: center;
}
