.topSpace{
    margin: 20px;
    margin-top: 60px;
}
.tableSection{
    margin-left: 20px;
}
.btn-circle.btn-sm {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    font-size: 8px;
    text-align: center;
}
.red{
    background-color: orangered;
color: white;
}

.tableHdResult {
    width: 60% !important;
}
 /*part add category*/
.topText{
  text-align: center;
}
.topButtonForm{
    margin-top:90px;
    margin-bottom: 30px;
    text-align: center;
}

